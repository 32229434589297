/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-console */

import { AxiosResponse, AxiosRequestConfig } from 'axios'

// const showLogs = process?.env?.NODE_ENV === 'development' && console.groupEnd
const showLogs = console.groupEnd

export const config = (values: any) => {
  if (!showLogs) {
    return
  }

  console.groupCollapsed(' %capi %cconfig %c@', 'color:grey; font-weight: 100', 'font-weight: bold')

  console.log('parameters%o', values)

  console.groupEnd()
}

export const logCall = (method: string | undefined, url: string | undefined, configuration: AxiosRequestConfig) => {
  if (!showLogs) {
    return
  }

  console.groupCollapsed(' %capi call %c' + method + ' ' + url, 'color:grey; font-weight: 100', 'font-weight: bold;')

  console.log('%c config %o', 'font-weight: bold;', configuration)

  console.groupEnd()
}

export const logResponse = (method: string | undefined, url: string | undefined, response: AxiosResponse) => {
  if (!showLogs) {
    return
  }

  console.groupCollapsed(
    ' %capi response %c' + method + ' ' + url,
    'color:grey; font-weight: 100',
    'font-weight: bold;'
  )

  if (response?.data) {
    console.log('%c data %o', 'font-weight: bold;', response.data)
  }

  if (response?.status) {
    console.log('%c status %o', 'font-weight: bold;', response.status)
  }

  console.groupEnd()
}

export const logError = (error: any) => {
  if (!showLogs) {
    return
  }

  const method = error.response?.config?.method
  const url = error.response?.config?.url
  const status = error.response?.status
  console.groupCollapsed(
    ' %capi error %c' + method + ' ' + url + ' ' + status,
    'color:grey; font-weight: 100',
    'font-weight: bold; color: FireBrick;'
  )

  if (error && error.response) {
    console.log('%c config %o', 'font-weight: bold;', error.response.config)

    if (error.response.data.errors) {
      console.log('%c errors %o', 'font-weight: bold;', error.response.data.errors)
    } else {
      console.log('%c data %o', 'font-weight: bold;', error.response.data)
    }
  }

  console.groupEnd()
}
