import aInstance from 'services/api'

export const mutateAddVote = ({ placeId, trackUri }: { placeId: number; trackUri: string }) => {
  console.log({ placeId, trackUri })
  const url = `/places/${placeId}/add-vote`
  return aInstance.post(url, { trackUri })
}

export const mutateRemoveVote = ({ placeId, trackUri }: { placeId: number; trackUri: string }) => {
  const url = `/places/${placeId}/remove-vote`
  return aInstance.post(url, { trackUri })
}
