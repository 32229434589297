export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyA-uAMrYai-_40z3qE3gIbTOlwqIZ05MQE',
  authDomain: 'la-gramola-e5610.firebaseapp.com',
  databaseURL: 'https://la-gramola-e5610.firebaseio.com',
  projectId: 'la-gramola-e5610',
  storageBucket: 'la-gramola-e5610.appspot.com',
  messagingSenderId: '234927795906',
  appId: '1:234927795906:web:13775c82ad68b28521555b',
  measurementId: 'G-KFYT7MT3B3',
}
