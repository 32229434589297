import { useCallback, useState } from 'react'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import Avatar from '@mui/material/Avatar'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import * as yup from 'yup'

import Copyright from 'components/molecules/copyright'
import { login, setJWTToken } from 'services/auth'

import { SContainer } from './styled'

const schema = yup
  .object({
    email: yup.string().email('Email no válido').required('Campo obligatorio'),
    password: yup.string().required('Campo obligatorio'),
  })
  .required()

type FormData = {
  email: string,
  password: string,
}

const Login = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver(schema) })

  const { mutate: loginMutate, isLoading } = useMutation(login, {
    onSuccess: ({ token }) => {
      setJWTToken(token)
      navigate(location?.state?.from?.pathname || '/', { replace: true })
    },
    onError: (err: Error) => toast.error(err.message || t('errorMessage')),
  })

  const onSubmit = useCallback(
    ({ email, password }: FormData) => {
      loginMutate({ username: email, password })
    },
    [loginMutate]
  )

  return (
    <SContainer maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          {t('loginTitle')}
        </Typography>
        <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            autoComplete='email'
            autoFocus
            label={t('email')}
            error={!!errors?.email}
            helperText={errors?.email?.message || ''}
            {...register('email')}
          />

          <FormControl fullWidth variant='outlined' margin='normal' required>
            <InputLabel htmlFor='password'>{t('password')}</InputLabel>
            <OutlinedInput
              id='password'
              type={showPassword ? 'text' : 'password'}
              label={t('password')}
              error={!!errors?.password}
              autoComplete='current-password'
              required
              {...register('password')}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={e => e.preventDefault()}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText id='password' error>
              {errors?.password?.message || ''}
            </FormHelperText>
          </FormControl>

          <LoadingButton type='submit' loading={isLoading} fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
            {t('loginAction')}
          </LoadingButton>

          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} variant='body2' to='/password-reset'>
                {t('loginForgotPassword')}
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} variant='body2' to='/register'>
                {t('loginRegister')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </SContainer>
  )
}

export default Login
