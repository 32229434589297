import { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Controller } from 'react-hook-form'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import Geodata from 'model/geodata'
import { getGeodata } from 'services/geodata'

type Props = {
  label: string
  placeholder: string
  name: string
  control: any
  error?: boolean
  helperText?: string
  autoFocus?: boolean
  required?: boolean
}

export default function AddressInput({
  label,
  placeholder,
  name,
  control,
  helperText,
  error = false,
  autoFocus = false,
  required = false,
}: Props) {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')

  const { data: options } = useQuery(['geodata', inputValue], ({ queryKey }) => getGeodata(`${queryKey?.[1]}`), {
    enabled: !!inputValue,
  })

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Autocomplete
            isOptionEqualToValue={(opt: Geodata, v: Geodata) => opt?.id === v?.id}
            id={name}
            sx={{}}
            noOptionsText={t('noOptionsText')}
            onBlur={field.onBlur}
            ref={field.ref}
            value={field.value || null}
            onChange={(event: any, newValue: Geodata | null) => {
              field.onChange(newValue)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue)
            }}
            options={options || []}
            autoHighlight
            getOptionLabel={option => option?.name || ''}
            renderOption={(props, option) => (
              <Box component='li' sx={{}} {...props}>
                {option.name} ({option.address})
              </Box>
            )}
            renderInput={params => (
              <TextField
                {...params}
                autoFocus={autoFocus}
                margin='normal'
                required={required}
                fullWidth
                label={label}
                error={error}
                helperText={helperText}
                placeholder={placeholder}
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        )
      }}
    />
  )
}
