import { useState, useEffect } from 'react'

import { getAuth, signInAnonymously, onAuthStateChanged, User } from 'firebase/auth'

const useFirebaseAuth = () => {
  const auth = getAuth()
  const [firebaseUser, setFirebaseUser] = useState<User>()

  useEffect(() => {
    const listener = onAuthStateChanged(auth, user => {
      if (user) {
        setFirebaseUser(user)
      } else {
        signInAnonymously(auth)
      }
    })

    return () => {
      listener()
    }
  }, [auth])

  useEffect(() => {
    signInAnonymously(auth)
  }, [auth])

  return { firebaseUser }
}

export default useFirebaseAuth
