import es from './es'

export const i18nConfig = {
  // the translations
  // (tip move them in a JSON file and import them,
  // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
  resources: {
    es: {
      translation: es,
    },
  },
  lng: 'es', // if you're using a language detector, do not define the lng option
  fallbackLng: 'es',

  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
}
