import aInstance from 'services/api'
import User from 'model/user'
import MyFile from 'model/file'

export const getMe = async (): Promise<User> => {
  const url = '/me'
  const { data } = await aInstance.get(url)
  return data
}

export const updateMe = async (formData: {
  displayName?: string
  address?: string
  latitude?: string
  longitude?: string
  shortDescription?: string
  description?: string
  defaultPlaylistId?: string
}) => {
  const url = '/me'
  const { data } = await aInstance.patch(url, formData)
  return data
}

export const updateMePictures = async (profilePictureFile: MyFile) => {
  const url = '/me/pictures'
  const formData = new FormData()

  formData.append('profilePictureFile', profilePictureFile)

  const res = await aInstance.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return res?.data
}

export const updateMeBgPictures = async ({
  backgroundPictureFiles,
  backgroundPictureRemovedFiles,
}: {
  backgroundPictureFiles?: MyFile[]
  backgroundPictureRemovedFiles?: number[]
}) => {
  const url = '/me/background-pictures'
  const formData = new FormData()

  if (backgroundPictureFiles?.length) {
    backgroundPictureFiles?.map(v => formData.append('files[]', v))
  }

  if (backgroundPictureRemovedFiles?.length) {
    backgroundPictureRemovedFiles.map(v => formData.append('removedIds[]', `${v}`))
  }

  const res = await aInstance.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return res?.data
}
