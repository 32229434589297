import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const SContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: fit-content;
`

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return props.theme.palette.primary.main
  }
  if (props.isDragReject) {
    return props.theme.palette.error.main
  }
  if (props.isFocused) {
    return '#2196f3'
  }
  return '#eeeeee'
}

export const SBox = styled(Box)`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-width: 2px;
  border-radius: 4px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;

  .emptyBox {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    padding: 36px;
  }

  .imageInputPlaceholder {
    text-align: center;
    color: #8a8a8a;
  }

  .imageContainer {
    position: relative;
  }
  .imageDeleteButton {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: ${props => props.theme.palette.secondary.main};
    border-radius: 50%;
    color: white;
    width: 32px;
    height: 32px;
    font-size: 18px;
  }

  .imageDeleteButton:hover {
    background-color: ${props => props.theme.palette.secondary.light};
  }
`
