import { useCallback } from 'react'

import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import MyFile from 'model/file'

import { SContainer, SBox } from './styled'

type PrevValue = { path: string; id: number }
type Props = {
  label: string
  maxFiles?: number
  onChange: (files: MyFile[]) => void
  value: MyFile[]
  previousValue?: PrevValue[]
  onRemovePreviousValue?: (ids: number[]) => void
  removedPreviousValuesIds?: number[]
  width?: number
  height?: number
}

const ImageInput = ({
  label,
  onChange,
  value = [],
  maxFiles = 1,
  width = 256,
  height = 256,
  previousValue,
  onRemovePreviousValue,
  removedPreviousValuesIds = [],
}: Props) => {
  const { t } = useTranslation()

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    maxFiles,
    maxSize: 6 * 1024 * 1024, // 6 MB
    accept: { 'image/*': [] },
    onDropAccepted: acceptedFiles => {
      const newFiles = [
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
        ...value,
      ]

      if (newFiles.length > maxFiles) {
        newFiles.length = maxFiles
      }

      onChange(newFiles)
    },
    onDropRejected: rejectedFiles => {
      const message = rejectedFiles[0]?.errors?.[0]?.message || t('errorMessage')
      toast.error(message)
    },
  })

  const onDeleteValue = useCallback(
    (e: any, item: MyFile, index: number) => {
      e.stopPropagation()
      onChange(value?.filter(v => v?.name !== item?.name))
    },
    [value, onChange]
  )

  const onDeletePrevValue = useCallback(
    (e: any, item: PrevValue, index: number) => {
      e.stopPropagation()
      if (!removedPreviousValuesIds?.includes(item?.id)) {
        onRemovePreviousValue?.([...removedPreviousValuesIds, item?.id])
      }
    },
    [removedPreviousValuesIds, onRemovePreviousValue]
  )

  const renderValueContent = useCallback(() => {
    if (!value?.length) {
      return null
    }

    return value?.map((item, i) => (
      <div className='imageContainer' key={`value-image-${i}`}>
        <Box component='img' sx={{ width, height, objectFit: 'cover' }} alt={item.name} src={item.preview} />
        <IconButton aria-label='delete' className='imageDeleteButton' onClick={e => onDeleteValue(e, item, i)}>
          <DeleteOutlineIcon fontSize='inherit' />
        </IconButton>
      </div>
    ))
  }, [value, width, height, onDeleteValue])

  const renderPreviousValueContent = useCallback(() => {
    if (!previousValue?.length) {
      return null
    } else if (maxFiles === 1 && value.length) {
      return null
    }

    return previousValue
      ?.filter(v => !removedPreviousValuesIds.includes(v?.id))
      .map((item, i) => (
        <div className='imageContainer' key={`prev-value-image-${i}`}>
          <Box component='img' sx={{ width, height, objectFit: 'cover' }} alt={undefined} src={item.path} />
          {item?.id > -1 && onDeletePrevValue ? (
            <IconButton aria-label='delete' className='imageDeleteButton' onClick={e => onDeletePrevValue(e, item, i)}>
              <DeleteOutlineIcon fontSize='inherit' />
            </IconButton>
          ) : null}
        </div>
      ))
  }, [previousValue, maxFiles, value.length, removedPreviousValuesIds, width, height, onDeletePrevValue])

  const renderEmptyContent = useCallback(() => {
    if (value?.length || previousValue?.filter(v => !removedPreviousValuesIds.includes(v?.id))?.length) {
      return null
    }

    return (
      <Box className='emptyBox' sx={{ width, height }}>
        <Typography className='imageInputPlaceholder' variant='body1'>
          {maxFiles > 1 ? t('imagesInputPlaceholder') : t('imageInputPlaceholder')}
        </Typography>
      </Box>
    )
  }, [height, width, maxFiles, previousValue, removedPreviousValuesIds, value, t])

  return (
    <SContainer>
      <Typography variant='subtitle1'>{label}</Typography>
      <SBox {...getRootProps({ isFocused, isDragAccept, isDragReject })} mt={1}>
        <input {...getInputProps()} />
        {renderValueContent()}
        {renderPreviousValueContent()}
        {renderEmptyContent()}
      </SBox>
    </SContainer>
  )
}

export default ImageInput
