import aInstance from 'services/api'

export const mutateSpotifySessionStart = () => {
  const url = '/sessions/start'
  return aInstance.post(url)
}

export const mutateSpotifySessionStop = () => {
  const url = '/sessions/stop'
  return aInstance.post(url)
}

export const mutateSpotifyTrackUpdate = (data: any) => {
  const url = '/sessions/track-finished'
  return aInstance.post(url, data)
}

export const mutateSpotifyAddTrackToQueue = (data: any) => {
  const url = '/sessions/add-track-to-queue'
  return aInstance.post(url, data)
}
