import { useState, useEffect } from 'react'

import SpotifyPlayer from 'react-spotify-web-playback'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from '@tanstack/react-query'

import { SPOTIFY_EVENT_TYPES } from 'config/constants'
import { getMe } from 'services/user'
import { mutateSpotifyTrackUpdate } from 'services/sessions'
import useSpotifyTokenObserver from 'hooks/use-spotify-token-observer'

import { SBox, spotifyPlayerStyles } from './styled'

const SpotifyBar = () => {
  const { t } = useTranslation()
  const [defaultPlaylist, setDefaultPlaylist] = useState('')
  const { data: user } = useQuery(['me'], getMe, { staleTime: 24 * 3600 * 1000 })
  const isValidToken = useSpotifyTokenObserver(user)
  const trackUpdateMutation = useMutation(mutateSpotifyTrackUpdate)

  useEffect(() => {
    if (!defaultPlaylist && user?.defaultPlaylistId && user?.spotifyAccessToken && isValidToken) {
      setDefaultPlaylist(user?.defaultPlaylistId)
    }
  }, [user, defaultPlaylist, isValidToken])

  if (!defaultPlaylist) {
    return null
  }

  // TODO: RETURN DISCLAIMER IF SESSION IS NOT ENABLED

  return (
    <SBox>
      <SpotifyPlayer
        syncExternalDevice
        persistDeviceSelection
        magnifySliderOnHover
        name={t('appName') || ''}
        token={user?.spotifyAccessToken || ''}
        uris={[`spotify:playlist:${defaultPlaylist}`]}
        callback={spotifyState => {
          // console.log({ spotifyState })
          if (spotifyState.type === SPOTIFY_EVENT_TYPES.TRACK_UPDATE) {
            trackUpdateMutation.mutate({ trackUri: spotifyState.track?.uri })
          }
        }}
        styles={spotifyPlayerStyles}
      />
    </SBox>
  )
}

export default SpotifyBar
