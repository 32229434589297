import { styled } from '@mui/material/styles'
import Modal from '@mui/material/Modal'

export const SModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .MuiBox-root {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: white;
    height: 80%;
    width: 80%;
    max-width: 860px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  }

  .MuiList-root {
    overflow: auto;
  }

  .Mui-selected {
    background-color: ${props => props.theme.palette.secondary.light};
  }
  .MuiButtonBase-root {
    border-radius: 4px;
  }
  .MuiButtonBase-root:hover {
    background-color: ${props => props.theme.palette.secondary.light}60;
  }
`
