import { useQuery } from '@tanstack/react-query'

import { getMe } from 'services/user'

import Profile from './view'

const ProfileContainer = () => {
  const { data: user } = useQuery(['me'], getMe, { staleTime: 24 * 3600 * 1000 })

  if (!user) {
    return null
  }

  return <Profile user={user} />
}

export default ProfileContainer
