import axios, { AxiosError, AxiosHeaders } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

import * as WSLogger from 'config/api-logger'
import { API_URL } from 'config/api'
import { getJWTToken , logout } from 'services/auth'

export const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: { 'Content-type': 'application/json' },
})

axiosInstance.interceptors.request.use(
  async req => {
    WSLogger.logCall(req.method, req.url, req)

    const JWTToken = getJWTToken()

    // TYPESCRIPT AXIOS BUG FIX
    const headers = req.headers as AxiosHeaders
    headers.set('Authorization', `Bearer ${JWTToken}`)
    req.headers = headers

    return req
  },
  err => {
    WSLogger.logError(err)
    return Promise.reject(err)
  }
)

axiosInstance.interceptors.response.use(
  res => {
    WSLogger.logResponse(res?.config?.method, res?.config?.url, res)
    return res
  },
  async err => {
    WSLogger.logError(err)
    return Promise.reject(err)
  }
)

const refreshAuthLogic = async (failedRequest: AxiosError) => {
  try {

    // TODO: ADD REFRESH LOGIC
    
    // const { refreshToken } = await SessionService.getSessionToken()
    // const { id: userId } = await SessionService.getSessionUser()

    // if (refreshToken) {
    //   const { data: token } = await AuthService.refreshToken({ userId, refresh: refreshToken })
    //   if (failedRequest?.response?.config?.headers) {
    //     failedRequest.response.config.headers.Authorization = `Bearer ${token.accessToken}`
    //   }

    //   await SessionService.setSessionToken(token)
    //   return Promise.resolve()
    // } else {
    //   logout()
    //   return Promise.reject()
    // }
    logout()
    return await Promise.reject()
  } catch (e) {
    logout()
    return Promise.reject()
  }
}

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, { statusCodes: [401] })

export const axiosPublicInstance = axios.create({
  baseURL: API_URL,
  headers: { 'Content-type': 'application/json' },
})

axiosPublicInstance.interceptors.request.use(
  async req => {
    WSLogger.logCall(req.method, req.url, req)
    return req
  },
  err => {
    WSLogger.logError(err)
    return Promise.reject(err)
  }
)

axiosPublicInstance.interceptors.response.use(
  res => {
    WSLogger.logResponse(res?.config?.method, res?.config?.url, res)
    return res
  },
  async err => {
    WSLogger.logError(err)
    return Promise.reject(err)
  }
)

export default axiosInstance
