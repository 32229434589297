/* eslint-disable import/order */
import { FC } from 'react'

// REACT QUERY
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

// MATERIAL UI
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles'

// STYLED COMPONENTS
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

// REACT TOASTIFY
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// ROUTER
import { RouterProvider } from 'react-router-dom'

// i18n
import { use } from 'i18next'
import { initReactI18next } from 'react-i18next'

import { i18nConfig } from 'assets/i18n'

// Default MUI styles
import CssBaseline from '@mui/material/CssBaseline'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import { router } from 'config/router'
import { styledTheme } from 'config/styled-components'
import { materialTheme } from 'config/material-ui'
import { reactQueryConfig } from 'config/react-query'

// FIREBASE
import { initializeApp } from 'firebase/app'
import { FIREBASE_CONFIG } from 'config/firebase'
export const firebaseApp = initializeApp(FIREBASE_CONFIG)

use(initReactI18next) // passes i18n down to react-i18next
  .init(i18nConfig)

export const queryClient = new QueryClient(reactQueryConfig)

const App: FC = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <MaterialThemeProvider theme={materialTheme}>
          <StyledThemeProvider theme={styledTheme}>
            <RouterProvider router={router} />
          </StyledThemeProvider>
        </MaterialThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      <ToastContainer />
      <CssBaseline />
    </>
  )
}

export default App
