import { FC } from 'react'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import SpotifyConnect from 'components/molecules/spotify-connect'
import SpotifyAddPlaylist from 'components/molecules/spotify-add-playlist'
import SpotifyEnableSession from 'components/molecules/spotify-enable-session'
import User from 'model/user'

import { SGrid } from './styled'

type Props = {
  user?: User
  isUserLoading?: boolean
  refetchMe: () => Promise<any>
  setShowPlaylistModal: (open: boolean) => void
}

const HomeHelperPanel: FC<Props> = ({ user, isUserLoading, refetchMe, setShowPlaylistModal }) => {
  const { t } = useTranslation()

  return (
    <SGrid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h6' mb={1}>
            {t('spotifyHelpTitle')}
          </Typography>
          <Typography variant='body1'>{t('spotifyHelpText')}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} md={8} lg={4}>
        <SpotifyConnect user={user} isLoading={isUserLoading} refetchMe={refetchMe} />
      </Grid>

      <Grid item xs={12} md={4} lg={4}>
        <SpotifyAddPlaylist
          user={user}
          isLoading={isUserLoading}
          onShowPlaylistModal={() => setShowPlaylistModal(true)}
        />
      </Grid>

      <Grid item xs={12} md={4} lg={4}>
        <SpotifyEnableSession user={user} isLoading={isUserLoading} refetchMe={refetchMe} />
      </Grid>
    </SGrid>
  )
}

export default HomeHelperPanel
