export const styledTheme = {
  palette: {
    primary: {
      light: '#1b444b',
      main: '#0A2F35',
      dark: '#0A2F35',
    },
    secondary: {
      light: '#1c8498',
      main: '#187180',
      dark: '#124e56',
    },
  },
}

//   primary: '#0A2F35',
//   secondary: '#187180',
//   accent: '#F7A325',
