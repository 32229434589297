import { useCallback, useState } from 'react'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import Avatar from '@mui/material/Avatar'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import Copyright from 'components/molecules/copyright'
import { VALIDATIONS } from 'config/constants'
import { login, register, setJWTToken } from 'services/auth'

import { SContainer } from './styled'

const schema = yup
  .object({
    email: yup.string().email('Email no válido').required('Campo obligatorio'),
    password: yup
      .string()
      .required('Campo obligatorio')
      .matches(
        VALIDATIONS.PASSWORD_REGEX,
        'Debe tener al menos 8 caracteres (una mayúscula, una minúscula, un número y un caracter especial)'
      ),
    repeatPassword: yup.string().oneOf([yup.ref('password'), null], 'La contraseña debe coincidir'),
    terms: yup.boolean().oneOf([true], 'Campo obligatorio'),
  })
  .required()

type FormData = {
  email: string,
  password: string,
  repeatPassword: string,
  terms: boolean,
}

const Register = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)

  const {
    register: formRegister,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver(schema) })

  const { mutate: loginMutate, isLoading: loginLoading } = useMutation(login, {
    onSuccess: ({ token }) => {
      setJWTToken(token)
      navigate(location?.state?.from?.pathname || '/', { replace: true })
    },
  })

  const { mutate: registerMutate, isLoading: registerLoading } = useMutation(register, {
    onSuccess: data => {
      toast.success(t('registerSuccess'))
      loginMutate(data)
    },
    onError: (err: Error) => toast.error(err.message || t('errorMessage')),
  })

  const onSubmit = useCallback(
    ({ email, password }: FormData) => {
      const data = {
        email,
        plainPassword: password,
      }
      registerMutate(data)
    },
    [registerMutate]
  )

  return (
    <SContainer maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          {t('registerTitle')}
        </Typography>
        <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
          <TextField
            autoFocus
            margin='normal'
            required
            fullWidth
            id='email'
            autoComplete='email'
            label={t('email')}
            error={!!errors?.email}
            helperText={errors?.email?.message || ''}
            {...formRegister('email')}
          />

          <FormControl fullWidth variant='outlined' margin='normal' required>
            <InputLabel htmlFor='password'>{t('password')}</InputLabel>
            <OutlinedInput
              id='password'
              type={showPassword ? 'text' : 'password'}
              label={t('password')}
              error={!!errors?.password}
              autoComplete='current-password'
              required
              {...formRegister('password')}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={e => e.preventDefault()}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText id='password' error>
              {errors?.password?.message || ''}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth variant='outlined' margin='normal' required>
            <InputLabel htmlFor='repeatPassword'>{t('repeatPassword')}</InputLabel>
            <OutlinedInput
              id='repeatPassword'
              type={showRepeatPassword ? 'text' : 'password'}
              label={t('repeatPassword')}
              error={!!errors?.repeatPassword}
              autoComplete='repeat-password'
              required
              {...formRegister('repeatPassword')}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                    onMouseDown={e => e.preventDefault()}
                    edge='end'
                  >
                    {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText id='repeatPassword' error>
              {errors?.repeatPassword?.message || ''}
            </FormHelperText>
          </FormControl>

          <Grid item xs={12}>
            <FormControl error={!!errors.terms}>
              <FormControlLabel
                control={<Checkbox required color={'primary'} {...formRegister('terms')} />}
                label={t('registerTerms')}
              />
            </FormControl>
          </Grid>

          <LoadingButton
            loading={registerLoading || loginLoading}
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
          >
            {t('registerAction')}
          </LoadingButton>

          <Grid container>
            <Grid item>
              <Link component={RouterLink} variant='body2' to='/login'>
                {t('registerLogin')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </SContainer>
  )
}

export default Register
