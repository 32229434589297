import { useMemo } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
// import ListSubheader from '@mui/material/ListSubheader'
import PersonIcon from '@mui/icons-material/Person'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined'

import { getMe } from 'services/user'

import { SListItemButton } from './styled'

const ListItems = ({ open }: { open: boolean }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { data: user } = useQuery(['me'], getMe, { staleTime: 24 * 3600 * 1000 })

  const musicCompleted = useMemo(() => (user?.spotifyAccessToken && user?.defaultPlaylistId ? true : false), [user])
  const profileCompleted = useMemo(
    () => (user?.address && user?.shortDescription && user?.description ? true : false),
    [user]
  )
  const imagesCompleted = useMemo(() => (user?.pictures?.length && user?.profilePicture ? true : false), [user])

  return (
    <>
      <SListItemButton color='inherit' selected={location?.pathname === '/'} onClick={() => navigate('/')}>
        <ListItemIcon>
          <DashboardIcon />
          {!open && !musicCompleted ? <PriorityHighIcon className={'warningIconClosed'} /> : null}
        </ListItemIcon>
        <ListItemText primary={t('dashboardMenu')} />
        {open && !musicCompleted ? (
          <ListItemIcon className={'warningIcon'}>
            <PriorityHighIcon />
          </ListItemIcon>
        ) : null}
      </SListItemButton>

      <SListItemButton color='inherit' selected={location?.pathname === '/profile'} onClick={() => navigate('profile')}>
        <ListItemIcon>
          <PersonIcon />
          {!open && !profileCompleted ? <PriorityHighIcon className={'warningIconClosed'} /> : null}
        </ListItemIcon>
        <ListItemText primary={t('profileMenu')} />
        {open && !profileCompleted ? (
          <ListItemIcon className={'warningIcon'}>
            <PriorityHighIcon />
          </ListItemIcon>
        ) : null}
      </SListItemButton>

      <SListItemButton color='inherit' selected={location?.pathname === '/images'} onClick={() => navigate('images')}>
        <ListItemIcon>
          <PermMediaOutlinedIcon />
          {!open && !imagesCompleted ? <PriorityHighIcon className={'warningIconClosed'} /> : null}
        </ListItemIcon>
        <ListItemText primary={t('imagesMenu')} />
        {open && !imagesCompleted ? (
          <ListItemIcon className={'warningIcon'}>
            <PriorityHighIcon />
          </ListItemIcon>
        ) : null}
      </SListItemButton>
    </>
  )
}

export default ListItems
