import { createBrowserRouter } from 'react-router-dom'

import RequireAuth from 'components/system/require-auth'
import Dashboard from 'components/system/dashboard'
import Home from 'components/pages/home'
import Profile from 'components/pages/profile'
import Images from 'components/pages/images'
import Login from 'components/pages/login'
import Register from 'components/pages/register'

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <RequireAuth>
        <Dashboard />
      </RequireAuth>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'images',
        element: <Images />,
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
])
