/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'

import { mutateSpotifyRefreshToken } from 'services/spotify'
import User from 'model/user'

const useSpotifyTokenObserver = (user?: User) => {
  const queryClient = useQueryClient()
  const [tokenExpirationTime, setTokenExpirationTime] = useState<number | null>()

  const { mutate: rereshSpotifyTokenMutate } = useMutation(mutateSpotifyRefreshToken, {
    onSuccess: updatedUser => {
      queryClient.setQueryData(['me'], updatedUser)
    },
  })

  useEffect(() => {
    // CREATE TOKEN EXPIRATION TIMEOUT
    let tokenExpirationTimeout: ReturnType<typeof setTimeout> | undefined = undefined

    // IF EXPIRATION TIME SETTED, SET TIMEOUT TO REFRESH TOKEN
    if (tokenExpirationTime) {
      tokenExpirationTimeout = setTimeout(rereshSpotifyTokenMutate, tokenExpirationTime)
    } else if (tokenExpirationTimeout) {
      clearTimeout(tokenExpirationTimeout)
      tokenExpirationTimeout = undefined
    }

    return () => {
      if (tokenExpirationTimeout) {
        clearTimeout(tokenExpirationTimeout)
        tokenExpirationTimeout = undefined
      }
    }
  }, [tokenExpirationTime])

  const checkTokenExpired = useCallback((userInfo: User) => {
    const spotifyTokenExpiresIn = userInfo?.spotifyTokenExpiresIn ? moment(userInfo?.spotifyTokenExpiresIn) : null
    if (spotifyTokenExpiresIn) {
      const tokenExpired = spotifyTokenExpiresIn.isBefore()
      const diff = spotifyTokenExpiresIn.diff(moment())
      if (tokenExpired) {
        // REFRESH TOKEN IF IS EXPIRED
        rereshSpotifyTokenMutate()
      } else if (diff) {
        // REFRESH TOKEN 1 MIN BEFORE EXPIRE
        const newTokenExpirationTime = diff - 60 * 1000
        if (newTokenExpirationTime !== tokenExpirationTime) {
          setTokenExpirationTime(newTokenExpirationTime)
        }
      } else {
        setTokenExpirationTime(null)
      }
    }
  }, [])

  useEffect(() => {
    if (user) {
      checkTokenExpired(user)
    }
  }, [user])

  return moment(user?.spotifyTokenExpiresIn).isAfter(moment())
}

export default useSpotifyTokenObserver
