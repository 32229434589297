import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import ListItemButton from '@mui/material/ListItemButton'

export const SBox = styled(Box)`
  .MuiDrawer-paper {
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.colors.white.main};
  }

  .MuiDrawer-paper .MuiListItemIcon-root {
    color: ${props => props.theme.colors.white.main};
  }
`

export const SListItemButton = styled(ListItemButton)`
  background-color: ${props =>
    props.selected ? props.theme.palette.secondary.main : props.theme.palette.primary.main} !important;

  .warningIcon {
    color: ${props => props.theme.colors.accent.main} !important;
    justify-content: flex-end;
  }

  .warningIconClosed {
    color: ${props => props.theme.colors.accent.main} !important;
    position: absolute;
    top: 2px;
    right: 12px;
    width: 24px;
    height: 24px;
  }
`
