/* eslint-disable max-len */
const es = {
  appName: 'La GramolApp',
  noOptionsText: 'No hay opciones',
  validationsMandatory: 'Campo obligatorio',
  validationsEmail: 'Email no válido',
  email: 'Email',
  password: 'Contraseña',
  repeatPassword: 'Repeteir contraseña',
  searchPlaceNameTitle: 'Busque el nombre de su local',
  searchPlaceName: 'Nombre del local',
  searchPlaceNamePlaceholder: 'Búsqueda en google',
  displayName: 'Nombre que se mostrará',
  displayNamePlaceholder: 'Nombre para mostrar',
  address: 'Dirección de su local',
  addressPlaceholder: 'Dirección de su local',
  shortDescription: 'Descripción corta',
  shortDescriptionPlaceholder: 'Breve descripción del local',
  description: 'Descripción',
  descriptionPlaceholder: 'Descripción detallada',
  errorMessage: 'Ha ocurrido un error',
  loginTitle: 'Inicia sesión',
  loginAction: 'Iniciar sesión',
  loginRegister: '¿No tienes una cuenta? Regístrate',
  loginForgotPassword: 'He olvidado mi contraseña',
  registerTitle: 'Regístrate',
  registerTerms: 'Acepto los términos y condiciones *',
  registerAction: 'Registrarse',
  registerLogin: 'Ya tengo cuenta. Iniciar sesión',
  registerSuccess: 'Registrado con éxito',
  dashboardMenu: 'Dashboard',
  profileMenu: 'Perfil',
  profileTitle: 'Datos del local',
  profileAction: 'Guardar',
  profileSuccess: 'Información actualizada con éxito',
  imagesMenu: 'Imágenes',
  imagesTitle: 'Imágenes',
  imagesProfile: 'Imagen principal:',
  imagesBackground: 'Imágenes del local (máximo 4):',
  imagesAction: 'Guardar',
  imageProfileSuccess: 'Imagen de perfil actualizada con éxito',
  imagesBackgroundSuccess: 'Imágenes del local actualizadas con éxito',
  imageInputPlaceholder: 'Pinche aquí para seleccionar o arrastre una imagen',
  imagesInputPlaceholder: 'Pinche aquí para seleccionar o arrastre una o varias imágenes',
  spotifyHelpTitle: 'Empieza a utilizar tu GramolApp',
  spotifyHelpText:
    'Sigue estos tres sencillos pasos para activar la música de la GramolApp en tu local. Una vez tengas una sesión activa tus clientes podrán votar las canciones de tu playlist. Recuerda desactivar la sesión antes de cerrar esta página web para que a los usuarios no les aparezca como activa tu sesión.',
  spotifyConnectHelp:
    'Todavía no has conectado tu cuenta de Spotify. Es necesario para poder usar la GramolApp en tu local.',
  spotifyConnect: 'Conectar con Spotify',
  spotifyAddPlaylistHelp:
    '¡Ya has conectado tu cuenta! Ahora elige una playlist de tu biblioteca para que suene en tu local',
  spotifyAddPlaylist: 'Elegir playlist',
  selectPlaylistReload: 'Volver a cargar playlists',
  selectPlaylistModalTitle: 'Elegir playlist',
  selectPlaylistModalSubtitle:
    'Elige una de tus playlist guardadas para mostrar en la aplicación a todos los usuarios. Puedes cambiarla en cualquier momento y todas las playlist que crees en Spotify se verán aquí reflejadas.',
  spotifyEnableSessionHelp:
    'Activa la sesión para empezar a reproducir música y que todos los usuarios puedan votar tus canciones.',
  spotifyEnableSessionAction: 'Activar sesión',
  votesListTitle: 'Votaciones de los usuarios',
  votes: '{{votes}} votos',
  removeVote: 'Eliminar voto',
  addVote: 'Votar canción',
  setNextTrack: 'Hacer siguiente canción en la lista',
  changePlaylist: 'Cambiar playlist',
  activeSession: ' Sesión activa',
  stoppedSession: 'Sesión detenida',
  prevTrackLabel: 'Acaba de sonar...',
  currentTrackLabel: 'Sonando ahora...',
  nextTrackLabel: 'Próxima canción...',
  switchSessionInfo:
    'Controla cuando quieras que se active la GramolApp de tu local. Recuerda desactivarla a la hora de cierre o cuando no quieras que los clientes puedan seguir votando las canciones.',
}

export default es
