const AUTH_SCOPES = [
  'streaming',
  'user-read-email',
  'user-read-playback-state',
  'user-modify-playback-state',
  'user-read-private',
  'user-read-recently-played',
  'playlist-read-private',
  'playlist-read-collaborative',
]

const AUTH_URI = 'https://accounts.spotify.com/authorize'
const CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID || ''
export const REDIRECT_URI = process.env.REACT_APP_SPOTIFY_REDIRECT_URI || ''

export const formatSpotifyAuthUrl = (): string => {
  const scopes = AUTH_SCOPES.join(' ')
  const params = `response_type=code&show_dialog=true&client_id=${CLIENT_ID}&scope=${encodeURIComponent(
    scopes
  )}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`
  const authUri = `${AUTH_URI}?${params}`
  return authUri
}
