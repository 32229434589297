import { createTheme } from '@mui/material/styles'

interface Colors  {
  accent: {
    light: React.CSSProperties['color'];
    main: React.CSSProperties['color'];
    dark: React.CSSProperties['color'];
  },
  white: {
    light: React.CSSProperties['color'];
    main: React.CSSProperties['color'];
    dark: React.CSSProperties['color'];
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    colors: Colors;
  }

  interface ThemeOptions {
    colors: Colors;
  }
}

export const materialTheme = createTheme({
  palette: {
    primary: {
      light: '#1b444b',
      main: '#0A2F35',
      dark: '#0A2F35',
    },
    secondary: {
      light: '#1c8498',
      main: '#187180',
      dark: '#124e56',
    },
  },
  colors: {
    accent: {
      light: '#F8B550',
      main: '#F7A325',
      dark: '#AC7219',
    },
    white: {
      light: '#FFF',
      main: '#FFF',
      dark: '#FFF',
    }
}
})
