import { useQuery } from '@tanstack/react-query'

import { getJWTToken } from 'services/auth'
import { getMe } from 'services/user'

export const USER_QUERY_KEY = ['me']

const useAuth = () => {
  const token = getJWTToken()
  const {
    data: user,
    isFetching,
    refetch: refetchMe,
  } = useQuery(USER_QUERY_KEY, getMe, { enabled: !!token, staleTime: 24 * 3600 * 1000 })
  return { user, refetchMe, isFetching }
}

export default useAuth
