import { styled } from '@mui/material/styles'
import Container from '@mui/material/Container'

export const SContainer = styled(Container)`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: ${props => props.theme.palette.secondary.main};
`
