import { useMemo } from 'react'

import { getFirestore, doc } from 'firebase/firestore'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import _orderBy from 'lodash/orderBy'

import User from 'model/user'
import Track from 'model/track'

const useFirestoreList = (user: User) => {
  const db = getFirestore()
  const placePath = user?.currentSession?.remotePath
  const docRef = doc(db, `places/${placePath}`)

  const [value] = useDocumentData(docRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  })

  const list: Track[] = useMemo(
    () =>
      _orderBy(
        value?.tracks || [],
        ['queuedAt', 'queuedByAdminAt', 'votedBy', 'name'],
        ['desc', 'desc', 'desc', 'asc']
      ),
    [value?.tracks]
  )

  return { list, player: value?.player }
}

export default useFirestoreList
