import { FC, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { User as FirebaseUser } from 'firebase/auth'
import { useMutation } from '@tanstack/react-query'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import List from '@mui/material/List'

import useFirestoreList from 'hooks/use-firestore-list'
import User from 'model/user'
import Track from 'model/track'
import { mutateSpotifyAddTrackToQueue } from 'services/sessions'
import { mutateAddVote, mutateRemoveVote } from 'services/places'
import TrackCard from 'components/molecules/track-card'

import { SGrid } from './styled'

type Props = {
  user: User
  firebaseUser: FirebaseUser
  setShowPlaylistModal: (open: boolean) => void
}

const HomeHelperPanel: FC<Props> = ({ user, firebaseUser, setShowPlaylistModal }) => {
  const { t } = useTranslation()
  const { list } = useFirestoreList(user)

  const { mutate: addVoteMutate } = useMutation(mutateAddVote)
  const { mutate: removeVoteMutate } = useMutation(mutateRemoveVote)
  const { mutate: spotifyAddTrackToQueueMutate } = useMutation(mutateSpotifyAddTrackToQueue)

  const onAddVote = useCallback(
    (track: Track) => {
      addVoteMutate({
        trackUri: track?.trackUri,
        placeId: user?.id,
      })
    },
    [user, addVoteMutate]
  )

  const onRemoveVote = useCallback(
    (track: Track) => {
      removeVoteMutate({
        trackUri: track?.trackUri,
        placeId: user?.id,
      })
    },
    [user, removeVoteMutate]
  )

  const onAddTrackToQueue = useCallback(
    (track: Track) => {
      spotifyAddTrackToQueueMutate({
        trackUri: track?.trackUri,
      })
    },
    [spotifyAddTrackToQueueMutate]
  )

  return (
    <SGrid container spacing={3}>
      <Grid container direction={'row'} pt={3}>
        <Typography variant='h6' flex={1} ml={3}>
          {t('votesListTitle')}
        </Typography>
        <Button variant='contained' onClick={() => setShowPlaylistModal(true)}>
          {t('changePlaylist')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <List>
          {list?.map(track => (
            <TrackCard
              key={track?.trackUri}
              track={track}
              firebaseUser={firebaseUser}
              onAddVote={onAddVote}
              onRemoveVote={onRemoveVote}
              onAddTrackToQueue={onAddTrackToQueue}
            />
          ))}
        </List>
      </Grid>
    </SGrid>
  )
}

export default HomeHelperPanel
