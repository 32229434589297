import { useState, useMemo } from 'react'

import HomeVotesList from 'components/organisms/home-votes-list'
import HomeHelperPanel from 'components/organisms/home-helper-panel'
import HomePlayerSummary from 'components/organisms/home-player-summary'
import SelectPlaylistModal from 'components/organisms/select-playlist-modal'
import useFirebaseAuth from 'hooks/use-firebase-auth'
import useAuth from 'hooks/use-auth'

import { SGrid } from './styled'

const Home = () => {
  const [showPlaylistModal, setShowPlaylistModal] = useState(false)

  const { user, isFetching: isUserLoading, refetchMe } = useAuth()
  const { firebaseUser } = useFirebaseAuth()

  const currentSession = useMemo(() => user?.currentSession, [user?.currentSession])

  return (
    <SGrid>
      {!currentSession && (
        <HomeHelperPanel
          user={user}
          isUserLoading={isUserLoading}
          refetchMe={refetchMe}
          setShowPlaylistModal={setShowPlaylistModal}
        />
      )}
      {currentSession && user && firebaseUser && <HomePlayerSummary user={user} refetchMe={refetchMe} />}

      {currentSession && user && firebaseUser && (
        <HomeVotesList user={user} firebaseUser={firebaseUser} setShowPlaylistModal={setShowPlaylistModal} />
      )}

      <SelectPlaylistModal
        user={user}
        refetchMe={refetchMe}
        show={showPlaylistModal}
        onClose={() => setShowPlaylistModal(false)}
      />
    </SGrid>
  )
}

export default Home
