import { useCallback, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'

import { mutateSpotifySessionStart } from 'services/sessions'
import User from 'model/user'

import { SPaper } from './styled'

type Props = { user?: User; isLoading?: boolean; refetchMe: () => Promise<any> }

const SpotifyEnableSession = ({ user, isLoading, refetchMe }: Props) => {
  const { t } = useTranslation()
  const defaultPlaylistId = useMemo(() => user?.defaultPlaylistId, [user?.defaultPlaylistId])
  const { mutate: spotifySessionStartMutate } = useMutation(mutateSpotifySessionStart, { onSuccess: () => refetchMe() })

  const onSpotifyConnect = useCallback(() => {
    spotifySessionStartMutate()
  }, [spotifySessionStartMutate])

  return (
    <SPaper
      sx={{
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        height: 240,
        opacity: !defaultPlaylistId || isLoading ? 0.6 : 1,
      }}
    >
      <Typography variant='body1' sx={{ mb: 4 }}>
        {t('spotifyEnableSessionHelp')}
      </Typography>
      <LoadingButton
        variant='contained'
        disabled={!defaultPlaylistId}
        loading={isLoading}
        color='secondary'
        onClick={onSpotifyConnect}
      >
        {t('spotifyEnableSessionAction')}
      </LoadingButton>
    </SPaper>
  )
}

export default SpotifyEnableSession
