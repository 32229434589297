import { styled } from '@mui/material/styles'
import Container from '@mui/material/Container'

export const SContainer = styled(Container)`
  .sectionSubtitle {
    margin-top: 48px;
    margin-bottom: 12px;
  }

  .MuiDivider-root {
    margin: 24px 0;
  }
`
