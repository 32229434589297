import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import CircularProgress from '@mui/material/CircularProgress'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { mutateSpotifySessionStart, mutateSpotifySessionStop } from 'services/sessions'
import useFirestoreList from 'hooks/use-firestore-list'
import User from 'model/user'
import Track from 'model/track'

import { SGrid } from './styled'

enum TrackCardTypes {
  NEXT = 'NEXT',
  CURRENT = 'CURRENT',
  PREV = 'PREV',
}

type TrackCardProps = {
  track: Track
  type: TrackCardTypes
}

const TrackCard: FC<TrackCardProps> = ({ track, type }) => {
  const { t } = useTranslation()

  const label = t(
    type === TrackCardTypes.PREV
      ? 'prevTrackLabel'
      : type === TrackCardTypes.CURRENT
      ? 'currentTrackLabel'
      : type === TrackCardTypes.NEXT
      ? 'nextTrackLabel'
      : '',
    {}
  )

  return (
    <Card sx={{ display: 'flex', width: 320, height: 100 }}>
      <CardMedia component='img' sx={{ width: 100 }} image={track?.cover} alt='Album cover' />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component='div' variant='body1'>
            {label}
          </Typography>
          <Typography component='div' variant='body1'>
            {track?.name}
          </Typography>
          <Typography component='div' variant='body2' color='text.secondary'>
            {track?.author}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  )
}

type Props = {
  user: User
  refetchMe: () => Promise<any>
}

const HomePlayerSummary: FC<Props> = ({ user, refetchMe }) => {
  const { t } = useTranslation()

  const { player } = useFirestoreList(user)

  const { mutateAsync: spotifySessionStopMutate, isLoading: spotifySessionStopLoading } =
    useMutation(mutateSpotifySessionStop)
  const { mutateAsync: spotifySessionStartMutate, isLoading: spotifySessionStartLoading } =
    useMutation(mutateSpotifySessionStart)

  const nextTrack: Track = player?.nextTrack
  const currentTrack: Track = player?.currentTrack
  const prevTrack: Track = player?.previousTrack

  const onChangeSession = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      await spotifySessionStartMutate()
    } else {
      await spotifySessionStopMutate()
    }

    await refetchMe()
  }

  return (
    <SGrid container sx={{ display: 'flex', flexDirection: 'row' }} mb={4} spacing={3}>
      <Grid item sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <Typography component='div' variant='h6' mb={1}>
          {'GramolApp activada:'}
        </Typography>

        {spotifySessionStopLoading || spotifySessionStartLoading ? (
          <CircularProgress color='inherit' size={28} style={{ marginLeft: 80 }} />
        ) : (
          <Tooltip title={t('switchSessionInfo')}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!user?.currentSession}
                    onChange={v => onChangeSession(v)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={user?.currentSession ? t('activeSession') : t('stoppedSession')}
              />
            </FormGroup>
          </Tooltip>
        )}
      </Grid>

      {player && (
        <Grid item>
          <Swiper
            navigation
            pagination
            slidesPerView={1}
            initialSlide={1}
            modules={[Navigation, Pagination]}
            className='tracks-swipper'
          >
            <SwiperSlide>
              <TrackCard track={prevTrack} type={TrackCardTypes.PREV} />
            </SwiperSlide>
            <SwiperSlide>
              <TrackCard track={currentTrack} type={TrackCardTypes.CURRENT} />
            </SwiperSlide>
            <SwiperSlide>
              <TrackCard track={nextTrack} type={TrackCardTypes.NEXT} />
            </SwiperSlide>
          </Swiper>
        </Grid>
      )}
    </SGrid>
  )
}

export default HomePlayerSummary
