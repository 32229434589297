import { FC, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { User as FirebaseUser } from 'firebase/auth'
import moment from 'moment'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'

import Track from 'model/track'

import { SListItem, SListItemText, SListItemSecondaryAction } from './styled'

type Props = {
  track: Track
  firebaseUser: FirebaseUser
  onAddVote: (track: Track) => void
  onRemoveVote: (track: Track) => void
  onAddTrackToQueue: (track: Track) => void
}

const TrackCard: FC<Props> = ({ track, firebaseUser, onAddVote, onRemoveVote, onAddTrackToQueue }) => {
  const { t } = useTranslation()
  const voted = useMemo(() => track.votedBy?.includes(firebaseUser?.uid), [firebaseUser?.uid, track.votedBy])

  return (
    <SListItem key={`list-item-${track.trackUri}`} divider>
      <ListItemAvatar>
        <Avatar alt={track.name} src={track.cover} />
      </ListItemAvatar>
      <SListItemText
        primary={`${track.name} - ${track.author}`}
        secondary={
          <Typography component='span' variant='body2' color='textPrimary'>
            {t('votes', { votes: track.votedBy?.length || 0 })}
          </Typography>
        }
      />
      {!track.queuedAt && !track.queuedByAdminAt ? (
        <SListItemSecondaryAction>
          <Tooltip title={voted ? t('removeVote') : t('addVote')}>
            <IconButton
              aria-label={voted ? 'remove vote to track' : 'add vote to track'}
              component='span'
              onClick={() => (voted ? onRemoveVote(track) : onAddVote(track))}
            >
              {voted ? <RemoveIcon /> : <AddIcon />}
            </IconButton>
          </Tooltip>

          <Tooltip title={t('setNextTrack')}>
            <IconButton aria-label='force next track' component='span' onClick={() => onAddTrackToQueue(track)}>
              <PlaylistAddIcon />
            </IconButton>
          </Tooltip>
        </SListItemSecondaryAction>
      ) : (
        <Typography component='span' variant='body2' color='textPrimary'>
          {`${t('played', {
            played: track.played
              ? moment(track.queuedByAdminAt ? track.queuedByAdminAt : track.queuedAt, 'X').fromNow()
              : t('no'),
          })}`}
        </Typography>
      )}
    </SListItem>
  )
}

export default TrackCard
