import aInstance, { axiosPublicInstance } from 'services/api'
import { queryClient } from 'components/system/app'
import { USER_QUERY_KEY } from 'hooks/use-auth'
export const TOKEN_KEY = '@token'

export const getJWTToken = () => localStorage.getItem(TOKEN_KEY)

export const setJWTToken = (token: string) => localStorage.setItem(TOKEN_KEY, token)

export const removeJWTToken = () => localStorage.removeItem(TOKEN_KEY)

export const register = async (data: {
  email: string,
  plainPassword: string,
}): Promise<{ username: string, password: string }> => {
  const url = '/public/register'
  await axiosPublicInstance.post(url, data)
  return { username: data?.email, password: data?.plainPassword }
}

export const login = async (data: { username: string, password: string }): Promise<{ token: string }> => {
  const url = '/login'
  const res = await aInstance.post(url, data)
  return res?.data
}

export const logout = () => {
  removeJWTToken()
  queryClient.invalidateQueries({ queryKey: USER_QUERY_KEY })
}

export const passwordResetRequest = (data: any) => {
  const url = '/public/password-reset/request'
  return aInstance.post(url, data)
}

export const passwordResetExecute = (data: any) => {
  const url = '/public/password-reset/execute'
  return aInstance.post(url, data)
}
