import CircularProgress from '@mui/material/CircularProgress'

import { SContainer } from './styled'

const Loading = () => {
  return (
    <SContainer>
      <CircularProgress color='inherit' size={60} />
    </SContainer>
  )
}

export default Loading
