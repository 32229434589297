/* eslint-disable no-useless-escape */

export const VALIDATIONS = {
  PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
}

export const SPOTIFY_EVENT_TYPES = {
  TRACK_UPDATE: 'track_update',
  PLAYER_UPDATE: 'player_update',
  PROGRESS_UPDATE: 'progress_update',
  STATUS_UPDATE: 'status_update',
}

export const DRAWER_WIDTH = 240
export const SPOTIFY_BAR_HEIGHT = 48
