import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import { SPOTIFY_BAR_HEIGHT } from 'config/constants'

export const SBox = styled(Box)`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: ${SPOTIFY_BAR_HEIGHT}px;
  background-color: #187180;
`

export const spotifyPlayerStyles = {
  activeColor: '#0A2F35',
  bgColor: '#187180',
  color: '#FFF',
  loaderColor: '#FFF',
  sliderColor: '#0A2F35',
  sliderHandleColor: '#0A2F35',
  sliderTrackColor: '#187180',
  trackArtistColor: '#FFF',
  trackNameColor: '#FFF',
}
