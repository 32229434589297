import aInstance from 'services/api'
import { PlaylistItem } from 'model/spotify'

export const mutateSpotifyConnect = (data: { code: string; redirectUri: string }) => {
  const url = '/spotify/connect'
  return aInstance.post(url, data)
}

export const fetchSpotifyPlaylists = async (params: any): Promise<{ items: PlaylistItem[]; total: number }> => {
  const url = '/spotify/me/playlists'
  const { data } = await aInstance.get(url, { params })
  return data
}

export const mutateSpotifyRefreshToken = async () => {
  const url = '/spotify/refresh-token'
  const { data } = await aInstance.post(url)
  return data
}
