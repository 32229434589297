import { useCallback, useEffect, useMemo } from 'react'

import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'

import { mutateSpotifyConnect } from 'services/spotify'
import { formatSpotifyAuthUrl, REDIRECT_URI } from 'config/spotify'
import User from 'model/user'

import { SPaper } from './styled'

type Props = { user?: User; isLoading?: boolean; refetchMe: () => Promise<any> }

const SpotifyConnect = ({ user, isLoading, refetchMe }: Props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const spotifyUserId = useMemo(() => user?.spotifyUserId, [user?.spotifyUserId])
  const { mutate: spotifyConnectMutate } = useMutation(mutateSpotifyConnect, { onSuccess: () => refetchMe() })

  useEffect(() => {
    const code = new URLSearchParams(location?.search).get('code')
    if (code && user && !spotifyUserId) {
      const data = { code, redirectUri: REDIRECT_URI }
      spotifyConnectMutate(data)
    }
  }, [location?.search, spotifyConnectMutate, user, spotifyUserId])

  const onSpotifyConnect = useCallback(() => {
    const spotifyAuthUrl = formatSpotifyAuthUrl()
    window.location.assign(spotifyAuthUrl)
  }, [])

  return (
    <SPaper
      sx={{
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        height: 240,
        opacity: spotifyUserId || isLoading ? 0.6 : 1,
      }}
    >
      <Typography variant='body1' sx={{ mb: 4 }}>
        {t('spotifyConnectHelp')}
      </Typography>
      <LoadingButton
        variant='contained'
        disabled={!!spotifyUserId}
        loading={isLoading}
        color='secondary'
        onClick={onSpotifyConnect}
      >
        {t('spotifyConnect')}
      </LoadingButton>
    </SPaper>
  )
}

export default SpotifyConnect
