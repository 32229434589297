import { FC, useCallback, useEffect } from 'react'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import Avatar from '@mui/material/Avatar'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import User from 'model/user'
import Geodata from 'model/geodata'
import AdrressInput from 'components/atoms/address-input'
import Copyright from 'components/molecules/copyright'
import { updateMe } from 'services/user'

import { SContainer } from './styled'

type Props = {
  user: User
}

type FormData = {
  geodata?: Geodata
  displayName: string
  address: string
  latitude: string
  longitude: string
  description?: string
  shortDescription?: string
}

const schema = yup
  .object({
    address: yup.string().required('Campo obligatorio'),
    latitude: yup.string().required('Campo obligatorio'),
    longitude: yup.string().required('Campo obligatorio'),
  })
  .required()

const Profile: FC<Props> = ({ user }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { mutate: profileMutate, isLoading: profileLoading } = useMutation(updateMe, {
    onSuccess: userRes => {
      queryClient.setQueryData(['me'], userRes)
      toast.success(t('profileSuccess'))
    },
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      displayName: user?.displayName || '',
      address: user?.address || '',
      latitude: user?.coordinates?.[1] || '',
      longitude: user?.coordinates?.[0] || '',
      shortDescription: user?.shortDescription || '',
      description: user?.description || '',
    },
  })

  const geodata = watch('geodata')
  useEffect(() => {
    if (geodata) {
      setValue('displayName', geodata.name || '', { shouldValidate: true })
      setValue('address', geodata.address || '', { shouldValidate: true })
      setValue('latitude', geodata.coordinates?.[1] || '', { shouldValidate: true })
      setValue('longitude', geodata.coordinates?.[0] || '', { shouldValidate: true })
    } else {
      setValue('displayName', user?.displayName || '')
      setValue('address', user?.address || '')
      setValue('latitude', user?.coordinates?.[1] || '')
      setValue('longitude', user?.coordinates?.[0] || '')
    }
  }, [geodata, setValue, user])

  const onSubmit = useCallback(
    ({ displayName, address, latitude, longitude, shortDescription, description }: FormData) => {
      const data = {
        displayName,
        address,
        latitude,
        longitude,
        shortDescription,
        description,
      }

      profileMutate(data)
    },
    [profileMutate]
  )

  return (
    <SContainer maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <PersonOutlineIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          {t('profileTitle')}
        </Typography>

        <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
          <Typography variant='body1' className='sectionSubtitle'>
            {t('searchPlaceNameTitle')}
          </Typography>

          <AdrressInput
            label={t('searchPlaceName')}
            placeholder={t('searchPlaceNamePlaceholder') || ''}
            name={'geodata'}
            control={control}
          />

          <Divider />

          <TextField
            margin='normal'
            required
            fullWidth
            id='displayName'
            autoComplete='display-name'
            label={t('displayName')}
            placeholder={t('displayNamePlaceholder') || ''}
            error={!!errors?.displayName}
            helperText={errors?.displayName?.message || ''}
            InputLabelProps={{ shrink: !!geodata || !!user?.displayName }}
            disabled={true}
            {...register('displayName')}
          />

          <TextField
            margin='normal'
            required
            fullWidth
            id='address'
            autoComplete='address'
            label={t('address')}
            placeholder={t('addressPlaceholder') || ''}
            error={!!errors?.address}
            helperText={errors?.address?.message || ''}
            InputLabelProps={{ shrink: !!geodata || !!user?.address }}
            disabled={true}
            {...register('address')}
          />

          <TextField
            margin='normal'
            fullWidth
            id='shortDescription'
            autoComplete='short-description'
            rows={2}
            multiline
            label={t('shortDescription')}
            placeholder={t('shortDescriptionPlaceholder') || ''}
            error={!!errors?.shortDescription}
            helperText={errors?.shortDescription?.message || ''}
            {...register('shortDescription')}
          />

          <TextField
            margin='normal'
            fullWidth
            id='description'
            autoComplete='description'
            rows={4}
            multiline
            label={t('description')}
            placeholder={t('descriptionPlaceholder') || ''}
            error={!!errors?.description}
            helperText={errors?.description?.message || ''}
            {...register('description')}
          />

          <LoadingButton loading={profileLoading} type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
            {t('profileAction')}
          </LoadingButton>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </SContainer>
  )
}

export default Profile
