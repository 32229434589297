import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import User from 'model/user'

import { SPaper } from './styled'

type Props = { user?: User; isLoading?: boolean; onShowPlaylistModal: () => void }
const SpotifyAddPlaylist = ({ user, isLoading, onShowPlaylistModal }: Props) => {
  const { t } = useTranslation()
  const defaultPlaylistId = useMemo(() => user?.defaultPlaylistId, [user?.defaultPlaylistId])

  return (
    <SPaper
      sx={{
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        height: 240,
        opacity: defaultPlaylistId || isLoading ? 0.6 : 1,
      }}
    >
      <Typography variant='body1' sx={{ mb: 4 }}>
        {t('spotifyAddPlaylistHelp')}
      </Typography>
      <Button
        variant='contained'
        disabled={!!defaultPlaylistId || isLoading}
        color='secondary'
        onClick={onShowPlaylistModal}
      >
        {t('spotifyAddPlaylist')}
      </Button>
    </SPaper>
  )
}

export default SpotifyAddPlaylist
