import { styled } from '@mui/material/styles'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'

export const SListItem = styled(ListItem)``

export const SListItemText = styled(ListItemText)`
  .MuiTypography-root {
    margin-right: 100px;
  }
`

export const SListItemSecondaryAction = styled(ListItemSecondaryAction)`
  .MuiIconButton-root {
    background-color: ${props => props.theme.colors.accent};
    margin-left: 20px;
  }
  .MuiSvgIcon-root {
    color: ${props => props.theme.colors.white};
  }
`
