import { useCallback, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from '@tanstack/react-query'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import RefreshIcon from '@mui/icons-material/Refresh'

import User from 'model/user'
import { fetchSpotifyPlaylists } from 'services/spotify'
import { mutateSpotifySessionStart, mutateSpotifySessionStop } from 'services/sessions'
import { updateMe } from 'services/user'

import { SModal } from './styled'

type Props = { user?: User; refetchMe: () => Promise<any>; show: boolean; onClose: () => void }

const SpotifyAddPlaylist = ({ user, refetchMe, show, onClose }: Props) => {
  const { t } = useTranslation()
  const defaultPlaylistId = useMemo(() => user?.defaultPlaylistId, [user?.defaultPlaylistId])
  const spotifyUserId = useMemo(() => user?.spotifyUserId, [user?.spotifyUserId])

  const { mutateAsync: spotifySessionStopMutate, isLoading: spotifySessionStopLoading } =
    useMutation(mutateSpotifySessionStop)
  const { mutateAsync: spotifySessionStartMutate, isLoading: spotifySessionStartLoading } =
    useMutation(mutateSpotifySessionStart)

  const onPlayListSelected = useCallback(async () => {
    if (user?.currentSession) {
      await spotifySessionStopMutate()
      await spotifySessionStartMutate()
    }

    refetchMe()
    onClose()
  }, [onClose, refetchMe, spotifySessionStartMutate, spotifySessionStopMutate, user])

  const { mutateAsync: spotifyAddPlayListMutate, isLoading: spotifyAddPlaylistLoading } = useMutation(updateMe, {
    onSuccess: () => onPlayListSelected(),
  })
  const { data: playlists, refetch: refetchSpotifyPlaylists } = useQuery(['playlists'], fetchSpotifyPlaylists, {
    enabled: !!spotifyUserId,
  })

  const isLoading = useMemo(
    () => (spotifyAddPlaylistLoading || spotifySessionStopLoading || spotifySessionStartLoading ? true : false),
    [spotifyAddPlaylistLoading, spotifySessionStartLoading, spotifySessionStopLoading]
  )

  const onSelectPlayList = useCallback(
    (id: string) => {
      if (!isLoading) {
        spotifyAddPlayListMutate({ defaultPlaylistId: id })
      }
    },
    [isLoading, spotifyAddPlayListMutate]
  )

  return (
    <SModal open={show} onClose={onClose}>
      <Box>
        <Grid item xs={12} sx={{ mt: 4, ml: 4, mr: 4, display: 'flex', alignItems: 'flex-start' }}>
          <Grid sx={{ flex: 1, display: 'flex', flexDirection: 'column', mb: 2 }}>
            <Typography variant='h6' sx={{ mb: 2 }}>
              {t('selectPlaylistModalTitle')}
            </Typography>
            <Typography variant='subtitle1'>{t('selectPlaylistModalSubtitle')}</Typography>
          </Grid>
          <Tooltip title={t('selectPlaylistReload')}>
            <IconButton
              sx={{ ml: 4 }}
              aria-label='reload playlist list'
              onClick={() => refetchSpotifyPlaylists()}
              disabled={isLoading}
              color='secondary'
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        <List sx={{ p: 4 }}>
          {playlists?.items?.map(v => (
            <ListItemButton
              key={`playlist-${v.id}`}
              selected={v.id === defaultPlaylistId}
              onClick={() => onSelectPlayList(v.id)}
            >
              <ListItemAvatar>
                <Avatar alt={v.name} src={v.images?.[0]?.url} />
              </ListItemAvatar>
              <ListItemText primary={v.name} />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </SModal>
  )
}

export default SpotifyAddPlaylist
