import { useCallback } from 'react'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import Avatar from '@mui/material/Avatar'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined'
import Typography from '@mui/material/Typography'

import Copyright from 'components/molecules/copyright'
import ImageInput from 'components/atoms/image-input'
import MyFile from 'model/file'
import { getMe, updateMePictures, updateMeBgPictures } from 'services/user'

import { SContainer } from './styled'

type FormData = {
  profilePictureFile?: MyFile
  backgroundPictureFiles?: MyFile[]
  backgroundPictureRemovedFiles?: number[]
}

const schema = yup.object({}).required()

const Images = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { control, handleSubmit, setValue, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
  })
  const watchBackgroundPictureRemovedFiles = watch('backgroundPictureRemovedFiles')

  const { data: user } = useQuery(['me'], getMe, { staleTime: 24 * 3600 * 1000 })
  const { mutateAsync: profileImageMutate, isLoading: profileLoading } = useMutation(updateMePictures, {
    onSuccess: () => toast.success(t('imageProfileSuccess')),
  })

  const { mutateAsync: backgroundPicturesMutate, isLoading: backgroundPicturesLoading } = useMutation(
    updateMeBgPictures,
    {
      onSuccess: () => toast.success(t('imagesBackgroundSuccess')),
    }
  )

  const onSubmit = useCallback(
    async ({ profilePictureFile, backgroundPictureFiles, backgroundPictureRemovedFiles }: FormData) => {
      if (profilePictureFile) {
        await profileImageMutate(profilePictureFile)
      }

      if (backgroundPictureFiles?.length || backgroundPictureRemovedFiles?.length) {
        await backgroundPicturesMutate({ backgroundPictureFiles, backgroundPictureRemovedFiles })
      }

      queryClient.refetchQueries(['me'])
    },
    [profileImageMutate, backgroundPicturesMutate, queryClient]
  )

  return (
    <SContainer maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <PermMediaOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          {t('imagesTitle')}
        </Typography>

        <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 4 }}>
          <Box>
            <Controller
              render={({ field }) => (
                <ImageInput
                  label={t('imagesProfile')}
                  onChange={v => field.onChange(v[0])}
                  value={field.value ? [field.value] : []}
                  previousValue={user?.profilePicture ? [{ path: user.profilePicture, id: -1 }] : []}
                  maxFiles={1}
                  width={256}
                  height={256}
                />
              )}
              name='profilePictureFile'
              control={control}
            />
          </Box>

          <Box mt={4}>
            <Controller
              render={({ field }) => (
                <ImageInput
                  label={t('imagesBackground')}
                  onChange={v => field.onChange(v)}
                  value={field.value || []}
                  previousValue={user?.pictures || []}
                  onRemovePreviousValue={(v: number[]) => setValue('backgroundPictureRemovedFiles', v)}
                  removedPreviousValuesIds={watchBackgroundPictureRemovedFiles}
                  maxFiles={4}
                  width={375}
                  height={276}
                />
              )}
              name='backgroundPictureFiles'
              control={control}
            />
          </Box>

          <LoadingButton
            loading={profileLoading || backgroundPicturesLoading}
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 4, mb: 2 }}
          >
            {t('imagesAction')}
          </LoadingButton>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </SContainer>
  )
}

export default Images
