import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

export const SGrid = styled(Grid)`
  .tracks-swipper {
    width: 420px;
  }

  .swiper-button-prev {
    font-size: 22px;
    margin-top: 0;
    top: 28px;
    color: ${props => props.theme.palette.secondary.light};
  }

  .swiper-button-prev::after {
    font-size: 24px;
  }

  .swiper-button-next {
    margin-top: 0;
    top: 28px;
    color: ${props => props.theme.palette.secondary.light};
  }

  .swiper-button-next::after {
    font-size: 24px;
  }

  .swiper-pagination-bullet-active {
    background-color: ${props => props.theme.palette.secondary.light};
  }

  .swiper-pagination-bullet-active {
    background-color: ${props => props.theme.palette.secondary.light};
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    height: 140px;
  }
`
